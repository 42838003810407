.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.handsfree-debugger {
  position: fixed;
  left: 10px;
  bottom: 10px;
  width: 300px;
}

#pointer {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: red;
  position: fixed;
}

.banner-cart-list {
  position: fixed;
  top: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}

.banner-cart-item {
  max-width: 600px;
  width: 100%;
  margin-bottom: 8px;
  background: #b2b2dd !important;
  border: solid 1px #3f51b5;
  div {
    padding: 10px 30px;
  }
}